body {
  background: linear-gradient(rgb(255, 204, 146),rgba(121, 96, 67, 0.2)) fixed !important;
}

a {
  color: #333333 !important;
  text-decoration: none !important;
}

a:hover {
  color: #696969 !important;
}

p,h1,h2,h3,h4,h5,h6 {
  color:#333333 !important;
}

h1 {
  margin-top:20px !important;
  text-align: center;
}

.card {
  background-color: rgba(255,255,255, 0.2) !important;
  width:90%;
  margin: 20px auto;
}

.card-group {
  gap:30px;
  justify-content: center;
  margin-top:40px;
}

.carousel-caption {
  top:200px !important;
}

.carousel {
  max-width:80vw !important;
  margin:auto !important;
}

.navbar {
  background-color: #efefef !important;
  padding:20px;
  min-height:80px;
}

.intro {
padding: 20px;
}

.intro > p {
margin-top:40px;
}

.comments-header {
  display:flex;
  flex-direction: column;
  align-items: center;
  gap:15px;
  margin-bottom: 20px; 
}

.comment-items {
  width:90%;
  margin:30px auto 0;
  background-color:rgba(255,255,255, 0.2);
  padding: 5%;
  border-radius: 5px 5px 0 0;
  border: 1px solid rgba(0,0,0,.125);
}

.comment-items > p {
  margin-bottom:0.25rem;
}

.btn-container {
  width:90%;
  margin:auto;
  display:flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  padding:15px;
  border-radius: 0 0 5px 5px;
  border: 1px solid rgba(0,0,0,.125);
  border-top-style: none;
  background-color: rgba(255,255,255, 0.5);
}

.btn-container > button {
  width:100px;
}

.comment-container {
margin-bottom:20px;
}

.card-img-top {
  padding:40px;
}

.edit-comment {
  width:90%;
  margin:auto;
}

@media only screen and (min-width: 768px) {

.carousel-img {
 max-width: 40vw;
 height:auto;
 margin: auto;
}

.intro {
  padding: 20px;
  max-width:1000px;
  margin:auto;
  }

.single-card {
  width:50%;
}

.comment-items {
  width:50%;
  margin:30px auto 0;
  background-color:rgba(255,255,255, 0.2);
  padding: 5%;
  border-radius: 5px 5px 0 0;
  border: 1px solid rgba(0,0,0,.125);
}

.btn-container {
  width:50%;
  margin:auto;
  display:flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  padding:15px;
  border-radius: 0 0 5px 5px;
  border: 1px solid rgba(0,0,0,.125);
  border-top-style: none;
  background-color: rgba(255,255,255, 0.5);
}

}